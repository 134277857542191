/* LoadingAnimation.css */

/* General Container */
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(
    135deg,
    #1a1a2e,
    #16213e
  ); /* Futuristic gradient */
  color: #fff;
  overflow: hidden;
  position: relative;
}

/* Pulsating Background */
.loading-container::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  background: radial-gradient(circle, rgba(0, 212, 255, 0.15), transparent);
  animation: pulse-bg 6s infinite linear;
  z-index: 0;
}

@keyframes pulse-bg {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

/* Loading Box */
.loading-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 220px;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent box */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  padding: 20px;
  position: relative;
  z-index: 1;
}

/* Road Animation */
.road {
  width: 100%;
  height: 60px;
  position: relative;
  background: linear-gradient(to right, #222, #333); /* Sleek road color */
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.road-line {
  width: 100%;
  height: 10px;
  background: repeating-linear-gradient(
    to right,
    #555,
    #555 20px,
    #666 20px,
    #666 40px
  );
  animation: road-scroll 3s linear infinite;
}

@keyframes road-scroll {
  from {
    background-position: 0;
  }
  to {
    background-position: -100px;
  }
}

@keyframes pulse {
  0%, 100% {
    box-shadow: 0 0 0 0 rgba(0, 212, 255, 0.7);
  }
  50% {
    box-shadow: 0 0 20px 20px rgba(0, 212, 255, 0);
  }
}

@keyframes drive {
  0% {
    left: -60px;
  }
  100% {
    left: 100%;
  }
}

@keyframes blink {
  0%, 100% {
    background: blue;
  }
  50% {
    background: red;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

/* Ambulance Animation */
.ambulance {
  width: 80px;
  height: 40px;
  background: url("../assets/ambulance.png") no-repeat center center;
  background-size: contain;
  position: absolute;
  left: -100px;
  animation: drive 5s ease-in-out infinite alternate;
  filter: drop-shadow(0px 0px 10px #00d4ff);
}

@keyframes drive {
  0% {
    left: -100px;
    transform: rotate(-2deg);
  }
  100% {
    left: calc(100% + 100px);
    transform: rotate(2deg);
  }
}

/* Ambulance Blue Light */
.blue-light {
  width: 10px;
  height: 10px;
  background-color: #00d4ff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
  animation: blink 0.7s ease-in-out infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

/* Loading Comments */
.loading-comment {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-top: 15px;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0, 212, 255, 0.8);
  animation: fadeIn 1s ease-in-out;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
